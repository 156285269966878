<template>
  <div>
    <RightWindow :title="title" @closedWindow="closedWindow">
        <div class="center_b_2">
          <ul class="ultab">
            <li class='hover'><a href="javascript:void(0)">详细信息</a></li>
          </ul>
        </div>
        <div class="center_b_3">
          <ul>
            <li class="up"><span>充值时间：</span>{{getDateTime(MmsData.data.chongzhishijian)}}</li>
            <li class="up"><span>充值账号：</span>{{MmsData.data.userPhone}}</li>
            <li class="up"><span>充值账号姓名：</span>{{MmsData.data.userXM}}</li>
            <li class="up"><span>充值门店：</span>{{MmsData.data.fenpeimendianName}}</li>
            <li class="up"><span>充值人员：</span>{{MmsData.data.chongzhirenyuanNames}}</li>
            <li class="up"><span>充值套餐：</span>{{MmsData.data.packageName}}</li>
            <li class="up"><span>充值金额：</span>{{MmsData.data.chongzhijine}}</li>
            <li class="up"><span>充值后余额：</span>{{MmsData.data.nowyue}}</li>
            <li class="up"><span>赠送金额：</span>{{MmsData.data.zengsongjine}}</li>
            <li class="up"><span>充值后赠送金额：</span>{{MmsData.data.nowzengsongyue}}</li>
            <li class="up"><span>积分：</span>{{MmsData.data.zengsongjifen}}</li>
            <li class="up"><span>充值后积分：</span>{{MmsData.data.nowjifen}}</li>
            <li class="up"><span>充值次数：</span><p v-html="MmsData.data.cishu"></p></li>
            <li class="up"><span>付款金额：</span>{{MmsData.data.fukuanjine}}</li>
            <li class="up"><span>支付方式：</span>{{getZFFS(MmsData.data.zhifufangshi)}}</li>
            <li class="up"><span>状态：</span>{{returnLB(MmsData.data.state)}}</li>
            <li class="up"><span>充值备注：</span>{{MmsData.data.info}}</li>
            <template v-if="MmsData.data.resetId">
              <li class="up"><span>撤销时间：</span>{{getDateTime(MmsData.data.resetDate)}}</li>
              <li class="up"><span>撤销人：</span>{{MmsData.data.resetName}}</li>
            </template>
          </ul>
          <div class="center_b_4">
            <button class="deltab" v-if="MmsData.data.state==1" @click="resetJL(MmsData.data.id)" v-pow:3401>撤销</button>
            <button class="remole" @click="closedWindow">关闭</button>
          </div>
        </div>
    </RightWindow>
  </div>

</template>
<script>
import axios from '@/http/axios'
import moment from 'moment'
import {zffsList} from '@/data/data'
export default {
  name: 'RRPSinfo',
  props:{
    MmsData:Object,
    title:String,
  },
  data() {
    return {
      zffsList,
    }
  },
  watch:{},
  created() {},
  components: {},
  mounted() {},
  methods: {
    getZFFS(num){
      let zffs=this.zffsList.find(e=>e.id==num)
      if(zffs) return zffs.name
      else{
        if(num==1) return "会员扣款"
        else return '无'
      }
    },
    resetJL(id){
      this.$trueOrFalse({
        title: '重要提示',
        content: `您确定撤销编号为${id}的这条记录？（次卡如消费过，将无法撤销，请先撤销消费记录！）`,
        torf: true,
        suc:()=>{
          axios.post('/record/resetCZById',{
            id:id,
          }).then((res) => {
            if(res.code==5000){
              this.$Mesg({content:"撤销成功！"})
              this.$parent.getData()
              this.closedWindow()
            }else this.$Mesg({content: res.msg,errOrSuc: 'err'})
          })
        }          
      })
    },
    returnLB(lb){
      if(lb==1){
        return '正常'
      }
      else{
        return '撤销'
      }
    },
    getDateTime(date){
      return moment(date).format("YYYY-MM-DD HH:mm:ss")
    },
    closedWindow(){
      this.$emit('closedWindow')
    },
    
  },
}
</script>
